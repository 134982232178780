
* {
  outline: none;
  box-sizing: border-box;
}
.mobile * {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
::selection {
  background: rgba(0,0,0,.3);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  font-family: 'Kulim Park', sans-serif;
  background: #fff;
  overflow-x: hidden;
}

#root {
  height: 100vh;
  width: 100%;
  /*overflow: hidden;*/
  /* disabled transform for lightbox reasons */
  margin: 0 auto;
  max-width: 1280px;

}

.app {
  display: flex;
  width: 100%;
  min-height: 100%;
  /*overflow: auto;*/
}

.page-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-loader img {
  height: 200px;
}

.nav {
  width: 160px;
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
  position: relative;
  z-index: 50;
  user-select: none;
  transition: 
    width .3s cubic-bezier(.12,.845,.305,1);
}

.app > .body {
  width: calc(100% - 150px);
  flex-grow: 1;
  flex-shrink: 1;
  padding: 40px;
  transition: 
    margin .3s cubic-bezier(.12,.845,.305,1), 
    width .3s cubic-bezier(.12,.845,.305,1);
}

h1 {
  margin-top: 0;
  line-height: 1em;
}
h1 .subtitle,
h1 .divider {
  margin-right: 15px;
  color: #ccc;
  font-weight: normal;
}

@media only screen and (max-width: 767px) {
  .nav:not(.open) {
    width: 0;
    /*flex-basis: 0;*/
  }
  .app > .body {
    margin-top: 50px;
    width: 100vw;
    flex-shrink: 0;
    padding: 20px;
  }
  .nav.open + .body {
    pointer-events: none;
  }
}