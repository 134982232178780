.nav {
  font-family: 'Kulim Park', sans-serif;
  font-weight: 300;
  transform: translateZ(0);
}
.nav-mobile {
  position: absolute;
  top: -50px;
  left: 0;
  height: 50px;
  width: 100vw;
  opacity: 0;
  pointer-events: none;
  background: #fff;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 
    left .3s cubic-bezier(.12,.845,.305,1), 
    top .3s cubic-bezier(.12,.845,.305,1), 
    opacity .35s;
}
.nav.open .nav-mobile {
  left: 150px;
}
.nav .toggle {
  position: absolute;
  left: 20px;
  height: 50px;
  display: flex;
  align-items: center;
}
.nav .hamburger {
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
}
.nav .hamburger-box {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 12px;
}
.nav .hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}
.nav .hamburger-inner, 
.nav .hamburger-inner:after, 
.nav .hamburger-inner:before {
  position: absolute;
  width: 20px;
  height: 2px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: black;
}
.nav .hamburger-inner:after, 
.nav .hamburger-inner:before {
  display: block;
  content: "";
}
.nav .hamburger-inner:before {
  top: -6px;
}
.nav .hamburger-inner:after {
  bottom: -6px;
}
.nav.open .hamburger-inner:before {
  transform: translate3d(4px,2.5px,0) rotate(35deg) scaleX(.7);
}
.nav.open .hamburger-inner:after {
  transform: translate3d(4px,-2.5px,0) rotate(-35deg) scaleX(.7);
}

.nav .icon {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%; 
  height: 100%;
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: pointer;
}

.nav-body {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
}

.nav .header {
  padding-top: 10px;
  padding-left: 40px;
  margin-bottom: -10px;
  max-width: 150px;
  min-height: 156px;
}

.nav #logo {
  width: 100%;
  margin-left: -25px;
  cursor: pointer;
}


.nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: 300;
}
.nav li {
  margin: 0;
  padding: 0;
  position: relative;
}
.nav .with-arrow > .link:before {
  content: '';
  display: block;
  height: .3em;
  width: .3em;
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  position: absolute;
  left: 25px;
  top: 17px;
  transform: rotate(45deg);
  transition: transform .3s cubic-bezier(.12,.845,.305,1);
  opacity: .7;
}
.nav .with-arrow.expanded > .link:before {
  transform: rotate(135deg) translate(-3px, 0px);
}
.nav li .link {
  display: inline-block;
  padding: 10px 40px;
  cursor: pointer;
}
.nav .link span {
  position: relative;
}
.nav .link span:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 2px;
  width: 0;
  z-index: -1;
  background: gray;
  transition: width .3s cubic-bezier(.12,.845,.305,1);
}
.nav .sub .link span:before {
  background: lightgray;
}
.app:not(.mobile) .nav .link:not(.active):not(.expanded):hover span:before {
  width: 70%;
}
.nav .sub {
  margin-left: 25px;
  color: gray;
  overflow: hidden;
  max-height: 0;
  transition: max-height .3s cubic-bezier(.12,.845,.305,1);
}
.nav .sub li:before {
  content: '';
  display: block;
  height: 100%;
  width: 2px;
  background: gray;
  position: absolute;
  left: 25px;
  top: 0;
  opacity: .2;
}
.nav .sub li:last-child:before {
  height: calc(100% - 10px);
}
.nav .expanded .sub {
  max-height: 300px;
  transition: max-height 3s cubic-bezier(.12,.845,.305,1);
}
.nav .link.active {
  font-weight: bold;
  color: black;
}
.nav .link.active span:before {
  background: black;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .nav-mobile {
    opacity: 1;
    top: 0;
    pointer-events: initial;
  }
  .nav .header {
    display: none;
  }
  .nav .nav-body {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-height: 100vh;
    overflow: auto;
  }
}