.gallery {
  margin-bottom: 150px;
  transform: translateZ(0);
}

.gallery .tiles {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -15px;
}

.gallery .tile {
  float: left;
  width: 33.33333%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 15px;
  display: inline-block;
  transition: transform .15s;
}
.app:not(.mobile) .gallery .tile.clickable:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.gallery .tile-contents {
  position: relative;
}
.gallery .tile img {
  width: 100%;
  display: inline-block;
  border-radius: 5px;
}
.gallery .tile img + * {
  margin-top: 5px;
}
.gallery .tile .title {
  font-weight: bold;
  padding: 0 10px;
  line-height: 1.1em;
}
.gallery .tile .description {
  color: gray;
  padding: 0 10px;
  font-size: .8em;
  line-height: 1.1em;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
}

@media only screen and (max-width: 767px) {
  .gallery .tile {
    width: 50%;
  }
}

@media only screen and (max-width: 480px) {
  .gallery .tile {
    width: 100%;
  }
}
