.project-wrapper,
.project {
  position: relative;
}
.project [aria-roledescription="carousel"] {
  width: 100%;
  display: flex;
  justify-content: center;
}
.project .react-slideshow-container {
  margin: 20px 0;
  width: 100%;
  position: relative;
}
.project .react-slideshow-wrapper.slide {
  background: rgb(245,245,255);
  border-radius: 10px;
  margin: 0 -20px;
  width: calc(100% + 40px);
  height: 100vh;
}

.project .react-slideshow-container button:first-of-type {
  transform: translateX(-25px);
}
.project .react-slideshow-container button:last-of-type {
  transform: translateX(25px);
}
.project .react-slideshow-container button,
.project .react-slideshow-container button:focus {
  background: #ed80da;
  height: 50px;
  width: 50px;
  z-index: 200;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: 5px solid white!important;
}
.project .react-slideshow-container button:hover,
.project .react-slideshow-container button:focus:hover {
  background: #5ccbf5;
}
.project .react-slideshow-container button:active:hover {
  background: #5967c9;
}
.project .react-slideshow-container button svg {
  height: 40%;
}
.project .react-slideshow-container button svg {
  fill: white;
}
.project .slide {
  height: 100%;
}
.project .slide > div {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  position: relative;
}
.project .slide > div label {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
  text-align: center;
  box-sizing: border-box;
}
.project .slide > div span {
  box-sizing: border-box;
  padding: .5em 1em;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: rgba(0,0,0,.5);
  color: white;
}

.project-wrapper .lb-container {
  background: rgba(0,0,0,.9);
}
.project-wrapper .lb-header .lb-button:hover {
  background-color: #ed80da;
}

.project .imgs {
  display: flex;
  justify-content: center;
}
.project .imgs div {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  padding: 0 10px;
}
.project .imgs.centered div {
  flex-grow: 0;
  flex-shrink: 1;
  width: auto;
}
.project .imgs.no-gap div {
  padding: 0;
}
.project .imgs.no-gap div:not(:first-child) img,
.project .imgs.no-gap div:not(:first-child) video {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.project .imgs.no-gap div:not(:last-child) img,
.project .imgs.no-gap div:not(:last-child) video {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.project .imgs div:first-child {
  padding-left: 0;
}
.project .imgs div:last-child {
  padding-right: 0;
}
.project .imgs img,
.project .imgs video {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
  border-radius: 5px;
}
.project img:not([data-skip-gallery]) {
  cursor: pointer;
  transition: opacity .1s;
}
.project img:not([data-skip-gallery]):hover {
  opacity: .8;
}
.project .imgs p {
  margin: 0;
  flex-grow: 0;
  flex-shrink: 0;
  color: rgb(115,115,155);
  text-align: center;
}
.project .slides.max200 .slide,
.project .imgs.max200 img,
.project .imgs.max200 video { 
  max-height: 200px; 
}
.project .slides.max300 .slide,
.project .imgs.max300 img,
.project .imgs.max300 video { 
  max-height: 300px; 
}
.project .slides.max400 .slide,
.project .imgs.max400 img,
.project .imgs.max400 video { 
  max-height: 400px; 
}
.project .slides.max500 .slide,
.project .imgs.max500 img,
.project .imgs.max500 video { 
  max-height: 500px; 
}
.project .slides.max600 .slide,
.project .imgs.max600 img,
.project .imgs.max600 video { 
  max-height: 600px; 
}
.project .slides.max700 .slide,
.project .imgs.max700 img,
.project .imgs.max700 video { 
  max-height: 700px; 
}
.project .slides.max800 .slide,
.project .imgs.max800 img,
.project .imgs.max800 video { 
  max-height: 800px; 
}
.project .slides.max900 .slide,
.project .imgs.max900 img,
.project .imgs.max900 video { 
  max-height: 900px; 
}

.project > iframe {
  width: 100%;
  height: 90vh;
}
.project iframe {
  border: 0;
  border-radius: 5px;
}

.project .video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.project .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.project .code {
  margin: 25px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.project .code iframe {
  max-width: 100%;
}

.project .instructions {
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  background: rgb(245,245,255);
  margin-bottom: 40px;
  font-family: monospace;
  max-width: 100%;
}

.project a {
  color: #5ccbf5;
  font-weight: bold;
}
.project a:hover {
  color: #ed80da;
}


@media only screen and (max-width: 767px) {
  .project .react-slideshow-wrapper.slide {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }
  .project .react-slideshow-container button,
  .project .react-slideshow-container button:focus {
    height: 40px;
    width: 40px;
  }
  .project .react-slideshow-container button:first-of-type {
    transform: translateX(10px);
  }
  .project .react-slideshow-container button:last-of-type {
    transform: translateX(-10px);
  }
}