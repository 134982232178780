.about {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  position: relative;
}
.about > div {
  max-width: 730px;
}
.about .blue { color: #2cbdf2;  } /* logo blue: #5ccbf5 */
.about .pink { color: #ed80da; }
.about .purple { color: #5967c9; }
.about .top {
  display: flex;
  align-items: flex-end;
  font-size: 30px;
  padding-top: 50px;
  font-weight: bold;
}
.about img {
  border-radius: 5px;
  width: 300px;
  height: 300px;
  flex-shrink: 0;
}
.about .header {
  display: flex;
  align-items: flex-end;
  margin-left: 40px;
}
.about .divider {
  height: 200px;
  width: 6px;
  border-radius: 3px;
  background: #5967c9;
  margin-left: 40px;
  margin-right: 25px;
  flex-shrink: 0;
  transform: translateY(3px);
}
.about .header p {
  display: inline-block;
  margin: 0;
  margin-bottom: -.15em;
}
.about .body {
  margin-top: 50px;
  margin-bottom: 40px;
}

.about .footer,
.about .body {
  font-size: 17px;
}

.about a {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.about p a:hover {
  opacity: .8;
}
.about .text p {
  margin-top: 0;
  margin-bottom: 1.3em;
}
.about .footer {
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}
.about .footer > .tags {
  display: flex;
  flex-grow: 1;
  align-items: center;
  white-space: nowrap;
}
.about .footer b {
  margin-right: 10px;
}
.about .footer .tags div:first-child {
  margin-left: 0;
}
.about .footer .tags div {
  margin: 5px 8px;
  text-decoration: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: .9em;
  margin-right: 5px;
  padding: 0;
  white-space: nowrap;
  font-weight: bold;
  /*color: #5967c9;*/
  /*background: rgba(89, 103, 201, .1);*/
}
.about .footer a:last-of-type {
  margin-right: 0;
}
.about .footer .tags div:hover {
  /*background: rgba(89, 103, 201, .3);*/
  cursor: pointer;
}
.about .footer .tags div a {
  display: inline-block;
  padding: 8px 12px;
  color: inherit;
  text-decoration: none;
}

.about ul {
  margin: -5px;
  padding: 0;
  margin-top: -10px;
  margin-bottom: 25px;
  list-style: none;
}
.about li {
  list-style: none;
  display: inline-block;
  text-decoration: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: .9em;
  margin: 5px;
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
}
.about .arrow {
  display: inline-block;
  transition: transform .15s;
}
.about li:hover .arrow,
.about .footer .tags div:hover .arrow {
  transform: translateX(3px);
}

.about ul.blue li,
.about div.blue div {
  background: hsl(196deg 88% 60% / 13%);
}
.about ul.blue li:hover,
.about div.blue div:hover {
  color: #0c9be9;
  background: hsl(196deg 88% 60% / 25%);
}
.about ul.pink li,
.about div.pink div {
  background: rgba(237, 128, 218, .1);
}
.about ul.pink li:hover,
.about div.pink div:hover {
  color: #dc4ccd;
  background: rgba(237, 128, 218, .3);
}
.about ul.purple li,
.about div.purple div {
  background: rgba(89, 103, 201, .1);
}
.about ul.purple li:hover,
.about div.purple div:hover {
background: rgba(89, 103, 201, .3);
}
.about ul li a {
  display: inline-block;
  padding: 8px 12px;
  color: inherit;
  text-decoration: none;
}


@media only screen and (max-width: 970px) {
  .about img {
    width: 250px;
    height: 250px;
  }
  .about .divider {
    margin: 0 25px;
  }
  .about ul li a {
    white-space: normal;
    word-break: break-word;
  }
}

@media only screen and (max-width: 767px) { 
  .about {
    margin-top: -20px;
  }
  .about #hi {
    display: inline-block;
    margin-right: .5em;
  }
  .about > div {
    width: 100%;
  }
  .about .top {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
    font-size: 30px;
  }
  .about .divider {
    height: 175px;
    width: 5px;
    border-radius: 2.5px;
    margin-left: 0;
    margin-right: 20px;
  }
  .about .header {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 0;
  }
  .about img {
    width: 100%;
    height: auto;
  }
  .about .body {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .about .footer,
  .about .body {
    font-size: 17px;
  }
  .about .footer b {
    margin-right: 5px;
  }
  .about .footer a {
    margin: 0 5px;
  }
}